@font-face {
    font-family: 'Mazzard H';
    src: url('MazzardH-Black.woff2') format('woff2'),
        url('MazzardH-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mazzard H';
    src: url('MazzardH-Bold.woff2') format('woff2'),
        url('MazzardH-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mazzard H';
    src: url('MazzardH-BlackItalic.woff2') format('woff2'),
        url('MazzardH-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mazzard H';
    src: url('MazzardH-ExtraLightItalic.woff2') format('woff2'),
        url('MazzardH-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mazzard H';
    src: url('MazzardH-Italic.woff2') format('woff2'),
        url('MazzardH-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mazzard H';
    src: url('MazzardH-ExtraBold.woff2') format('woff2'),
        url('MazzardH-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mazzard H';
    src: url('MazzardH-ExtraBoldItalic.woff2') format('woff2'),
        url('MazzardH-ExtraBoldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mazzard H';
    src: url('MazzardH-BoldItalic.woff2') format('woff2'),
        url('MazzardH-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mazzard H';
    src: url('MazzardH-ExtraLight.woff2') format('woff2'),
        url('MazzardH-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mazzard H';
    src: url('MazzardH-Medium.woff2') format('woff2'),
        url('MazzardH-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mazzard H';
    src: url('MazzardH-Light.woff2') format('woff2'),
        url('MazzardH-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mazzard H';
    src: url('MazzardH-Regular.woff2') format('woff2'),
        url('MazzardH-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mazzard H';
    src: url('MazzardH-MediumItalic.woff2') format('woff2'),
        url('MazzardH-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mazzard H';
    src: url('MazzardH-SemiBold.woff2') format('woff2'),
        url('MazzardH-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mazzard H';
    src: url('MazzardH-LightItalic.woff2') format('woff2'),
        url('MazzardH-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mazzard H';
    src: url('MazzardH-SemiBoldItalic.woff2') format('woff2'),
        url('MazzardH-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mazzard H';
    src: url('MazzardH-Thin.woff2') format('woff2'),
        url('MazzardH-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mazzard H';
    src: url('MazzardH-ThinItalic.woff2') format('woff2'),
        url('MazzardH-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

